<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Basic from "./basic";
import axios from "axios"

import {
  required,
  //email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Pool registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Basic },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      info: null,
      title: "Pool registration",
      componentKey: 0,
      num_latitude: 0.0,
      num_longitude: 0.0,
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Pools",
          href: "/management/pools"
        },
        {
          text: "Pool",
          active: true
        }
      ],
      companies: [],
      countries: [],
      states: [],
      cities: [],
      validToken: true,
      form: {
        id_country: "142",
        id_state: "",
        de_address: "",
        nm_pool: "",
        num_latitude: 0,
        num_longitude: 0,
        sn_publicPool: false
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      id_country: { required },
      id_state: { required },
      de_address: { required, minLength: minLength(5), maxLength: maxLength(200) },
      nm_pool: {required, minLength: minLength(2), maxLength: maxLength(50)}
    }
  },
  watch:{
    lat(newLat, oldLat){
      if(newLat != oldLat){
        this.lat = newLat;
        this.forceRerenderMap();
      }
    },
    lng(newLng, oldLng){
      if(newLng != oldLng){
        this.lng = newLng;
        this.forceRerenderMap();
      }
    },
    validToken(newValue){
      console.log(newValue);
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.getCountries();

    if( this.$route.params.id ){
      this.getPoolByID();
    }

    if(this.form.id_country != ""){
      this.getStatesByCountryID();
    }
  },
  methods: {
    getCountries(){
      Swal.fire({
        title: "Loading Countries",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      let app = this;
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/countries.php',
        {
          params: {
            request: 'getCountries',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if( response.data == "Invalid token"){
            Swal.close();
            this.validToken = false;
          }else{
            app.countries = response.data;
            Swal.close();
            if(this.$route.params.id){
              this.getStatesByCountryID();
            }
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },
    getStatesByCountryID(){
      let app = this;
      Swal.fire({
        title: "Loading States",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/states.php',
        {
          params: {
            request: 'getStatesByCountryID',
            idCountry: this.form.id_country,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if(response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if(response.status == 404){ app.$router.push({ name: 'Error-404' }); }
        if(response.status == 401){ app.$router.push({ name: 'Error-401' }); }

        if( response.status == 200 ){
          app.states = response.data;
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    getPoolByID(){
      Swal.fire({
        title: "Loading pool data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      let app = this;

      axios
      .get(
        window.serverPath+'dist/mvw/controllers/pools.php',
        {
          params: {
            request: 'getPoolByID',
            id: this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          app.site = response.data;

          this.form.id_country        = app.site.id_country;
          this.form.id_state          = app.site.id_state;
          this.form.de_address        = app.site.de_address;
          this.form.nm_pool           = app.site.nm_pool;

          this.form.num_latitude      = app.site.num_latitude;
          this.form.num_longitude     = app.site.num_longitude;

          this.num_latitude           = app.site.num_latitude;
          this.num_longitude          = app.site.num_longitude;

          if(app.site.sn_publicPool == 1){
            this.form.sn_publicPool        = true;  
          }else{
            this.form.sn_publicPool        = false;
          }
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load States! Please load this module again.", "error");
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    saveSite(e) {
      let app = this;

      e.preventDefault();

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_pool = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving pool.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        axios.post(window.serverPath+'dist/mvw/controllers/pools.php', {
          request: 'savePool',
          data: this.form,
          token: this.user.token,
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Your pool is correct "+action+"!", "success");
          app.$router.push({ name: 'pools' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
    setPosition(event){
      this.form.num_latitude = event.lat;
      this.form.num_longitude = event.lng;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveSite">
              <div class="row">
                <div class="col-sm-8">
                  <h4 class="card-title">Location Data</h4>
                  <p class="card-title-desc">Provide all location data of your site to get a better communication with your other sites.</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_pool">Pool name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_pool.$error }"
                          name="nm_pool"
                          id="nm_pool"
                          v-model="form.nm_pool"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_pool.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_pool.required">Pool name is required.</span>
                          <span v-if="!$v.form.nm_pool.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_pool.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_country">Country</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_country.$error }"
                          name="id_country"
                          id="id_country"
                          v-model="form.id_country"
                          @change="getStatesByCountryID"
                        >
                          <option value="">SELECT YOUR COUNTRY</option>
                          <option v-for="(country, index) in countries" :key="index" :value="country.id_country">{{country.nm_country}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_country.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_country.required">Country field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="id_state">State/Province</label>
                        <select
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.id_state.$error }"
                          name="id_state"
                          id="id_state"
                          v-model="form.id_state"
                          value=""
                        >
                          <option value="">SELECT YOUR STATE/PROVINCE</option>
                          <option v-for="(state, index) in states" :key="index" :value="state.id_state">{{state.nm_state}}</option>
                        </select>
                        <div v-if="submitted && $v.form.id_state.$error" class="invalid-feedback">
                          <span v-if="!$v.form.id_state.required">State/Province field is required.</span>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="de_address">Address</label>
                        <textarea
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.de_address.$error }"
                          name="de_address"
                          id="de_address"
                          v-model="form.de_address"
                          rows="3"
                          value=""
                        >
                        </textarea>
                        <div v-if="submitted && $v.form.de_address.$error" class="invalid-feedback">
                          <span v-if="!$v.form.de_address.required">Address field is required.</span>
                          <span v-if="!$v.form.de_address.minLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                          <span v-if="!$v.form.de_address.maxLength">This value length is invalid. It should be between 100 and 200 characters long.</span>
                        </div>

                        <div class="form-group">
                          <b-form-checkbox v-model="form.sn_publicPool" switch class="mb-1">
                            <label>Is this a public pool?</label>
                          </b-form-checkbox>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="num_latitude">Latitude</label>
                            <input
                              class="form-control"
                              name="num_latitude"
                              id="num_latitude"
                              v-model="form.num_latitude"
                              type="text"
                              placeholder="Enter latitude coordenate"
                              value=""
                            />
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label for="num_longitude">Longitude</label>
                            <input
                              class="form-control"
                              name="num_longitude"
                              id="num_longitude"
                              v-model="form.num_longitude"
                              type="text"
                              placeholder="Enter longitude coordenate"
                              value=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title mb-4">Site Location</h4>
                      <Basic :lat.sync="num_latitude" :lng.sync="num_longitude" :key="componentKey" @changeMarker="setPosition"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE POOL</button>
                    <router-link to="/pools" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
          </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>